<template>
  <div class="h-screen w-full flex items-center justify-center">
    <div class="w-92">
      <v-card elevation="0" ref="form">
        <LoginAftIcon />
        <div class="text-center">
          <div class="pt-10 pb-4 font-semibold text-2xl text-gray-900">
            {{ $t("loginadminview.logInTo") }}
          </div>
          <div class="pb-4 text-base text-gray-500">
            {{ $t("loginworkerview.ChooseLoginType") }}
          </div>
        </div>
        <v-card-text>
          <div class="flex justify-center items-center gap-5 pt-14">
            <v-btn
              depressed
              width="260"
              height="50"
              href="/login-manual-worker"
              color="primary"
              class="rounded-lg text-xl font-semibold"
            >
              {{ $t("loginworkerview.ManualLogin") }}
            </v-btn>
            <v-btn
              depressed
              width="260"
              height="50"
              href="/login-worker"
              class="rounded-lg text-xl font-semibold"
            >
              {{ $t("loginworkerview.RFIDLogin") }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import LoginAftIcon from "../assets/LoginAftIcon.vue";

export default {
  components: { LoginAftIcon },
};
</script>

<style scoped>
* {
  text-transform: none;
}
</style>
