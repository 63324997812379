<template>
  <div class="py-4 px-13 bg-[#fff] atlWrapper">
    <div
      v-if="machineStatus == 'Stopped' && !labelStationsLoader"
      class="w-[100%] pb-4"
    >
      <div
        class="flex justify-center items-center h-[60px] bg-[#F63D68] gap-3 rounded-[8px]"
      >
        <div class="w-[16.67px] h-[16.67px]">
          <v-img :src="require(`../../assets/alert-circle-icon.svg`)"></v-img>
        </div>
        <div class="font-[600] text-[18px] text-[#FEF3F2]">
          {{ $t("atlview.machineHasStoppedWorking") }}
        </div>
      </div>
    </div>
    <div v-if="isLongAlert" class="w-[100%] pb-4">
      <div
        class="flex justify-center items-center h-[60px] bg-[#F63D68] gap-3 rounded-[8px]"
      >
        <div class="w-[16.67px] h-[16.67px]">
          <v-img :src="require(`../../assets/alert-circle-icon.svg`)"></v-img>
        </div>
        <div class="font-[600] text-[18px] text-[#FEF3F2]">
          {{ longAlertMsg }}
        </div>
      </div>
    </div>
    <div class="flex items-center justify-start gap-11">
      <div class="flex items-center justify-center gap-7">
        <div class="text-[#344054] text-[24px] font-[700]">
          {{ machineName }}
        </div>
        <div
          v-if="machineStatus == 'Automatic'"
          class="flex justify-center items-center rounded-[16px] bg-[#ECFDF3] w-[99px] h-[28px] text-[#027A48] text-[14px] font-[600]"
        >
          {{ $t("adminshlview.production") }}
        </div>
        <div
          v-if="machineStatus == 'Planned Stop'"
          class="flex justify-center items-center rounded-[16px] bg-[#FF9C66] w-[220px] h-[28px] text-[#ECFDF3] text-[14px] font-[600]"
        >
          {{ $t("shlview.plannedStop") }}
        </div>
        <div
          v-if="machineStatus == 'Cleaning'"
          class="flex justify-center items-center rounded-[16px] bg-[#FF9C66] w-[99px] h-[28px] text-[#ECFDF3] text-[14px] font-[600]"
        >
          {{ $t("shlview.clean") }}
        </div>
        <div
          v-if="machineStatus == 'Prepare'"
          class="flex justify-center items-center rounded-[16px] bg-[#FF9C66] w-[99px] h-[28px] text-[#ECFDF3] text-[14px] font-[600]"
        >
          {{ $t("shlview.prepare") }}
        </div>
        <div
          v-if="machineStatus == 'Stopped'"
          class="flex justify-center items-center rounded-[16px] bg-[#F63D68] w-[145px] h-[28px] text-[#ECFDF3] text-[14px] font-[600]"
        >
          {{ $t("adminshlview.stopped") }}
        </div>
        <div
          v-if="machineStatus == 'Employee Break'"
          class="flex justify-center items-center rounded-[16px] bg-[#FF9C66] w-[99px] h-[28px] text-[#ECFDF3] text-[14px] font-[600]"
        >
          {{ $t("shlview.break") }}
        </div>
      </div>
      <div class="flex justify-center gap-8">
        <div>
          <!-- Automatic labeling tab -->
          <router-link :to="{ name: 'adminManualAtl' }">
            <div
              class="flex items-center justify-center text-[16px] text-[#344054] w-[212px] h-[40px] bg-[#F5F5FF] rounded-[6px]"
              :class="
                currentTab.includes('/admin-manual-atl')
                  ? 'text-[#0408E7] rounded-[6px] bg-[#F5F5FF]'
                  : ''
              "
            >
              {{ $t("adminatlview.automaticLabeling") }}
            </div>
          </router-link>
        </div>
        <div>
          <!-- Shiftlog tab -->
          <router-link :to="{ name: 'adminManualShl' }">
            <div
              class="flex items-center justify-center text-[16px] text-[#344054] w-[212px] h-[40px] rounded-[6px]"
            >
              {{ $t("adminatlview.shiftlog") }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-10 mr-7">
      <div class="flex justify-center items-center">
        <v-dialog v-model="createPackDialog" width="455">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              height="40"
              width="140"
              depressed
              color="#F5F5FF"
              class="rounded-[8px] create-pack-btn"
              >{{ $t("manualworkeratlview.createPackage") }}</v-btn
            >
          </template>

          <v-card>
            <div class="flex justify-center">
              <div>
                <v-card-title
                  ><div class="mx-auto">
                    <div class="text-[18px] text-[#101828] font-[600]">
                      {{ $t("manualworkeratlview.createPackage") }}
                    </div>
                  </div></v-card-title
                >
                <v-card-text>
                  <div class="flex justify-start">
                    <div class="w-[150px]">
                      <v-label>{{ $t("shlview.quantity") }}</v-label>
                      <v-text-field
                        class="w-[149px] h-[44px]"
                        :placeholder="currentOrder?.book_quantity ?? '0'"
                        dense
                        ref="name"
                        v-model="packageQuantity"
                        outlined
                        single-line
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="my-9"></div>
                  <div class="flex justify-start gap-2">
                    <div>
                      <v-btn
                        class="package-cancel-btn"
                        color="#ffffff"
                        depressed
                        width="174"
                        height="44"
                        @click="createPackDialog = false"
                        >{{ $t("shlview.cancel") }}</v-btn
                      >
                    </div>
                    <div>
                      <v-btn
                        class="package-save-btn"
                        color="primary"
                        depressed
                        width="174"
                        height="44"
                        @click="createPackage"
                        :loading="createPackageLoader"
                        >{{ $t("shlview.save") }}</v-btn
                      >
                    </div>
                  </div>
                </v-card-text>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>
      <div
        class="flex justify-center items-center w-[101px] h-[40px] text-[#344054] text-[14px] border-[#D0D5DD] border-[1px] rounded-[8px]"
      >
        <v-dialog v-model="createOrderDialog" width="455">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="#FFFFFF"
              height="40"
              width="155"
              depressed
              class="rounded-[8px] create-order-btn"
              >{{ $t("manualworkeratlview.addOrder") }}</v-btn
            >
          </template>

          <v-card>
            <div class="flex justify-center">
              <div>
                <v-card-title
                  ><div class="mx-auto">
                    <div class="text-[18px] text-[#101828] font-[600]">
                      {{ $t("manualworkeratlview.createOrder") }}
                    </div>
                  </div></v-card-title
                >
                <v-card-text>
                  <div class="flex justify-start">
                    <div class="w-[155px]">
                      <v-label>{{ $t("manualworkeratlview.orderNo") }}</v-label>
                      <v-text-field
                        class="w-[149px] h-[44px]"
                        placeholder="0"
                        ref="name"
                        dense
                        outlined
                        single-line
                        v-model="orderNumber"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="my-9"></div>
                  <div class="flex justify-start gap-2">
                    <div>
                      <v-btn
                        class="package-cancel-btn"
                        color="#ffffff"
                        depressed
                        width="174"
                        height="44"
                        @click="createOrderDialog = false"
                        >{{ $t("manualworkeratlview.cancel") }}</v-btn
                      >
                    </div>
                    <div>
                      <v-btn
                        class="package-save-btn"
                        color="primary"
                        depressed
                        width="174"
                        height="44"
                        :loading="createOrderLoader"
                        @click="createOrder"
                        >{{ $t("manualworkeratlview.save") }}</v-btn
                      >
                    </div>
                  </div>
                </v-card-text>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <div class="flex justify-between">
      <div>
        <div class="flex justify-start gap-5">
          <!-- Current order card  -->
          <div
            class="py-3 w-[323px] h-[153px] rounded-[8px] border-[1px] bg-[#ffffff] border-[#D0D5DD]"
            v-if="currentOrder"
          >
            <div class="px-3 pb-7 border-b-[1px] border-[#EAECF0]">
              <div class="flex justify-between">
                <div class="text-base font-semibold text-[#101828]">
                  {{ currentOrder.article_number }}
                </div>
                <div>
                  <div
                    class="w-[140px] h-[28px] rounded-2xl bg-[#F0F9FF] flex items-center justify-center gap-1"
                  >
                    <div class="w-[12.67px]">
                      <v-img
                        :src="require('../../assets/blue-box-icon.svg')"
                      ></v-img>
                    </div>
                    <div class="text-base">
                      {{ totalItemsProcessed }} /
                      {{ currentOrder.total_quantity }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-[#667085] text-sm font-normal">
                {{ currentOrder.production_order_number }}
              </div>
            </div>
            <div class="px-3 flex items-center justify-between mt-5">
              <div class="text-[#667085] font-normal text-sm">
                {{ $t("atlview.currentOrder") }}
              </div>
              <button
                class="flex items-center gap-1 py-2 px-4"
                @click="showUnLoadCurrentModal"
              >
                <div class="w-[15.83px]">
                  <v-img
                    :src="require('../../assets/violet-box-icon.svg')"
                  ></v-img>
                </div>
                <div class="text-[#4B4EFC] text-[14px]">
                  {{ $t("atlview.unload") }}
                </div>
              </button>
            </div>
          </div>
          <!-- Next order card  -->
          <div
            class="py-3 w-[323px] h-[153px] rounded-[8px] bg-[#ffffff] border-[1px] border-[#D0D5DD]"
            v-if="nextOrder"
          >
            <div class="px-3 pb-7 border-b-[1px] border-[#EAECF0]">
              <div class="flex justify-between">
                <div class="text-base font-semibold text-[#101828]">
                  {{ nextOrder.article_number }}
                </div>
                <div>
                  <div
                    class="w-[140px] h-[28px] rounded-2xl bg-[#F0F9FF] flex items-center justify-center gap-1"
                  >
                    <div class="w-[12.67px]">
                      <v-img
                        :src="require('../../assets/blue-box-icon.svg')"
                      ></v-img>
                    </div>
                    <div class="text-base">
                      {{ nextOrder.total_quantity }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-[#667085] text-sm font-normal">
                {{ nextOrder.production_order_number }}
              </div>
            </div>
            <div class="px-3 flex items-center justify-between mt-5">
              <div class="text-[#667085] font-normal text-sm">
                {{ $t("atlview.nextOrder") }}
              </div>
              <button
                class="flex items-center gap-1 py-2 px-4"
                @click="showUnLoadNextModal"
              >
                <div class="w-[15.83px]">
                  <v-img
                    :src="require('../../assets/violet-box-icon.svg')"
                  ></v-img>
                </div>
                <div class="text-[#4B4EFC] text-[14px]">
                  {{ $t("atlview.unload") }}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div v-if="showSuccessMsg">
          <v-alert color="#A6F4C5" width="400" height="60">
            <div class="flex justify-start items-center gap-2">
              <div class="w-[16.67px]">
                <v-img :src="require('../../assets/check-icon.svg')"></v-img>
              </div>
              <div class="text-[#054F31] text-lg">
                {{ successMsg }}
              </div>
            </div>
          </v-alert>
        </div>
      </div>
    </div>

    <div class="pb-5"></div>
    <!-- Automatic labeling table  -->
    <v-data-table
      dense
      :headers="
        atlViewLang === 'en'
          ? headers
          : atlViewLang === 'de'
          ? deHeaders
          : atlViewLang === 'cn'
          ? cnHeaders
          : atlViewLang === 'ro'
          ? roHeaders
          : atlViewLang === 'ba'
          ? baHeaders
          : atlViewLang === 'es'
          ? esHeaders
          : rsHeaders
      "
      :items="formattedPackages"
      item-key="id"
      class="border-2"
      :hide-default-footer="true"
      :no-data-text="$t('atlview.nodata')"
    >
      <template v-slot:[`item.id`]="{ item }">
        <div class="pr-16 text-[#344054]">{{ item.id }}</div>
      </template>

      <template v-slot:[`item.quantity`]="{ item }">
        <div>
          <div class="text-[#101828]">
            {{ item.quantity.actualQuantity }} /
            {{ item.quantity.requiredQuantity }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.startTime`]="{ item }">
        <div>
          <div class="text-[#101828]">{{ item.startTime.time }}</div>
          <div class="text-[#344054]">{{ item.startTime.date }}</div>
        </div>
      </template>

      <template v-slot:[`item.endTime`]="{ item }">
        <div>
          <div class="text-[#101828]">{{ item.endTime.time }}</div>
          <div class="text-[#344054]">{{ item.endTime.date }}</div>
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div>
          <div
            v-if="!item.status.packageStatus && item.status.erpStatus == '0'"
            class="text-[#0408E7] text-sm font-normal"
          >
            <div
              class="text-[#0408E7] text-sm font-normal cursor-pointer"
              @click="showClosePackageModal(item)"
            >
              {{ $t("atlview.closePackage") }}
            </div>
          </div>
          <div class="flex justify-start items-center gap-2" v-else>
            <div class="w-[16.67px]" v-if="item.status.packageStatus">
              <v-img :src="item.status.packIcon"></v-img>
            </div>
            <div v-else>
              <v-img :src="item.status.errorIcon"></v-img>
            </div>
            <div>
              {{ $t("atlview.package") }}
            </div>
            <div></div>
            <div class="w-[16.67px]" v-if="item.status.erpStatus == '1'">
              <v-img :src="item.status.packIcon"></v-img>
            </div>
            <div v-else>
              <v-img :src="item.status.errorIcon"></v-img>
            </div>
            <div>
              {{ $t("atlview.erp") }}
            </div>
          </div>
        </div>
      </template>

      <template v-slot:[`item.edit`]="{ item }">
        <v-dialog
          v-model="editDialog"
          :retain-focus="false"
          width="455"
          overlay-opacity="0.1"
          content-class="elevation-0"
        >
          <template v-slot:activator="{}">
            <div
              @click="showEditDialog(item)"
              v-if="!item.status.packageStatus && item.status.erpStatus == '0'"
              class="text-[#0408E7] text-sm font-normal cursor-pointer"
            >
              {{ $t("manualworkeratlview.edit") }}
            </div>
          </template>

          <v-card>
            <div class="flex justify-center">
              <div>
                <v-card-title
                  ><div class="mx-auto">
                    <div class="text-[18px] text-[#101828] font-[600]">
                      {{ $t("manualworkeratlview.editPackage") }}
                    </div>
                  </div></v-card-title
                >
                <v-card-text>
                  <div class="flex justify-start">
                    <div class="w-[150px]">
                      <v-label>{{
                        $t("manualworkeratlview.quantity")
                      }}</v-label>
                      <v-text-field
                        class="w-[149px] h-[44px]"
                        ref="name"
                        v-model="editPackageQuantity"
                        dense
                        outlined
                        single-line
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="my-9"></div>
                  <div class="flex justify-start gap-2">
                    <div>
                      <v-btn
                        class="package-cancel-btn"
                        color="#ffffff"
                        depressed
                        width="174"
                        height="44"
                        @click="editDialog = false"
                        >{{ $t("shlview.cancel") }}</v-btn
                      >
                    </div>
                    <div>
                      <v-btn
                        class="package-save-btn"
                        color="primary"
                        depressed
                        width="174"
                        height="44"
                        :loading="createPackageLoader"
                        @click="editPackage"
                        >{{ $t("shlview.save") }}</v-btn
                      >
                    </div>
                  </div>
                </v-card-text>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
    <PinDialog
      :isUnloadCurrent="isUnloadCurrent"
      :isUnloadNext="isUnloadNext"
      :isClosePackage="isClosePackage"
      @unload-currentOrder="unLoadCurrentOrder"
      @unload-nextOrder="unLoadNextOrder"
      @close-package="closePackage"
      ref="PinDialogRef"
    />
    <v-overlay :value="labelStationsLoader" color="grey">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <div class="position-alert" v-if="showAlert">
      <AlertComponent
        :alertColor="alertColor"
        :textColor="textColor"
        :alertMessage="alertMessage"
        :isSuccess="isSuccess"
        @hide-alert="hideAlert"
      />
    </div>
  </div>
</template>

<script>
import Api from "../../services/api";
import ApiNoToken from "../../services/apiNoToken";
import { format, parseISO } from "date-fns";
import PinDialog from "../../components/PinDialog.vue";
import { mapActions } from "vuex";
import AlertComponent from "../../components/AlertComponent.vue";

export default {
  components: { PinDialog, AlertComponent },
  data() {
    return {
      currentTab: this.$router.history.current.fullPath,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        { text: "Quantity", value: "quantity" },
        { text: "Start Time", value: "startTime" },
        { text: "End Time", value: "endTime" },
        { text: "", value: "edit" },
        { text: "Status", value: "status" },
      ],
      deHeaders: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        { text: "Menge", value: "quantity" },
        { text: "Startzeit", value: "startTime" },
        { text: "Endzeit", value: "endTime" },
        { text: "", value: "edit" },
        { text: "Status", value: "status" },
      ],
      esHeaders: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        { text: "Cantidad", value: "quantity" },
        { text: "Hora de inicio", value: "startTime" },
        { text: "Hora de finalización", value: "endTime" },
        { text: "", value: "edit" },
        { text: "Estado", value: "status" },
      ],
      cnHeaders: [
        {
          text: "身份证",
          align: "start",
          value: "id",
        },
        { text: "数量", value: "quantity" },
        { text: "开始时间", value: "startTime" },
        { text: "结束时间", value: "endTime" },
        { text: "", value: "edit" },
        { text: "状况", value: "status" },
      ],
      roHeaders: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        { text: "Cantitate", value: "quantity" },
        { text: "Ora de începere", value: "startTime" },
        { text: "Sfârșitul timpului", value: "endTime" },
        { text: "", value: "edit" },
        { text: "Stare", value: "status" },
      ],
      baHeaders: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        { text: "Količina", value: "quantity" },
        { text: "Vrijeme početka", value: "startTime" },
        { text: "Vrijeme završetka", value: "endTime" },
        { text: "", value: "edit" },
        { text: "Status", value: "status" },
      ],
      rsHeaders: [
        {
          text: "ИД",
          align: "start",
          value: "id",
        },
        { text: "Количина", value: "quantity" },
        { text: "Почетно време", value: "startTime" },
        { text: "Време завршетка", value: "endTime" },
        { text: "", value: "edit" },
        { text: "Статус", value: "status" },
      ],

      orders: [
        {
          id: "23354",
          quantity: "1500 / 1500",
          startTime: "14:09:37",
          endTime: "",
          status: {
            close: "Close Package",
          },
        },
        {
          id: "80800",
          quantity: "1500 / 1500",
          startTime: "14:09:37",
          endTime: "",
          edit: "",
          status: {
            close: "",
            package: require("../../assets/check-icon.svg"),
          },
        },
      ],

      createOrderLoader: false,
      orderNumber: "",
      createPackageLoader: false,
      packageQuantity: 0,
      editPackageQuantity: "",
      editDialog: false,
      createPackDialog: false,
      createOrderDialog: false,
      labelStationsLoader: false,
      totalItemsProcessed: "",
      currentOrder: null,
      nextOrder: null,
      packages: [],
      formattedPackages: [],
      isUnloadCurrent: false,
      isUnloadNext: false,
      isClosePackage: false,
      currentPackage: null,
      showLoader: false,
      successMsg: "",
      showSuccessMsg: false,

      longAlertMsg: "",
      isLongAlert: false,

      showAlert: false,
      alertColor: "",
      textColor: "",
      alertMessage: "",
      isSuccess: true,
      aftMacId: "",

      routeMachineId: "",
      // dynamicMachineId: "4", // temporal
      dynamicMachineId: localStorage.getItem("aftMacId"),
      timer: null,
      errorCount: 0,
    };
  },

  computed: {
    atlViewLang() {
      return this.$store.state.translation.i18n.locale;
    },
    currentUserData() {
      return this.$store.state.authentication.currentUser?.data;
    },

    userMachineId() {
      return this.currentUserData?.user?.machine_id;
    },

    machineStatus() {
      return this.$store.state.machines.machineStatus;
    },

    isAdmin() {
      return this.$store.state.authentication.isAdmin;
    },

    machineName() {
      return this.$store.state.machines.machineName;
    },

    otherMachineStatus() {
      return this.$store.state.machines.otherMachineStatus;
    },
  },

  watch: {
    currentOrder(newOrder) {
      this.packageQuantity = newOrder?.book_quantity ?? "0";
    },
    packageQuantity(newValue) {
      if (this.currentOrder) {
        this.currentOrder.book_quantity = Number(newValue) || 0;
      }
    },
  },

  methods: {
    // get Label station stats
    getLabelStationStats(showLoader) {
      this.labelStationsLoader = showLoader;
      ApiNoToken()
        .get(
          `/station/get-label-stats/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`
        )
        .then((res) => {
          if (res.data.status == "success") {
            let dataList = res.data.data;
            dataList.forEach((item) => {
              this.structureData(item);
            });
            this.formattedPackages = [...this.formatPackages(this.packages)];
            this.labelStationsLoader = false;
          }
        })
        .catch((err) => {
          this.showError(err);
          this.labelStationsLoader = false;
          clearInterval(this.timer);
          this.errorCount++;
        })
        .finally(() => {
          clearInterval(this.timer);
          this.timer = setInterval(() => {
            if (this.errorCount < 10) {
              this.getLabelStationStats(false);
            } else {
              clearInterval(this.timer);
            }
          }, 5000);
        });
    },

    structureData(data) {
      let hasCurrentOrder = Object.hasOwnProperty.call(data, "current_order");
      let hasNextOrder = Object.hasOwnProperty.call(data, "next_order");
      if (Object.hasOwnProperty.call(data, "total_items_processed")) {
        this.totalItemsProcessed = data.total_items_processed;
        this.setCurrentShiftStartDate(data.current_shift_start_date);
        this.setCurrentShiftEndDate(data.current_shift_end_date);
        this.setMachineName(data.machine_name);
      } else if (hasCurrentOrder || hasNextOrder) {
        this.currentOrder = data.current_order?.[0];
        this.nextOrder = data.next_order?.[0];
      } else if (Object.hasOwnProperty.call(data, "packages")) {
        this.packages = [...data.packages];
      } else if (Object.hasOwnProperty.call(data, "machine_status")) {
        this.setMachineStatus(data.machine_status);
      }
    },

    createPackage() {
      this.createPackageLoader = true;
      let newData = {
        quantity: parseInt(this.packageQuantity, 10),
      };
      Api()
        .post(
          `/station/create-manual-package/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          newData
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccessAlert(res.data.detail);
          }
          this.createPackageLoader = false;
          this.createPackDialog = false;
          this.packageQuantity = 0;
        })
        .catch((err) => {
          this.showError(err);
          this.createPackageLoader = false;
        });
    },

    openEditDialog(packageQuantity) {
      this.editPackageQuantity = packageQuantity;
      this.editDialog = true;
    },

    showEditDialog(item) {
      this.editDialog = true;
      this.editPackageQuantity = item.actual_quantity;
    },

    editPackage() {
      this.createPackageLoader = true;
      let newData = {
        quantity: parseInt(this.editPackageQuantity, 10),
      };
      Api()
        .post(
          `/station/create-manual-package/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          newData
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccessAlert(res.data.detail);
          }
          this.createPackageLoader = false;
          this.editDialog = false;
        })
        .catch((err) => {
          this.showError(err);
          this.createPackageLoader = false;
        });
    },

    createOrder() {
      this.createOrderLoader = true;
      let newData = {
        order_number: this.orderNumber,
      };
      Api()
        .post(
          `/station/create-manual-order/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          newData
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccessAlert(res.data.detail);
          }
          this.createOrderLoader = false;
          this.createOrderDialog = false;
          this.orderNumber = "";
        })
        .catch((err) => {
          this.showError(err);
          this.createOrderLoader = false;
        });
    },

    formatPackages(packages) {
      return packages.map((packItem) => {
        packItem = this.addPackageFeatures(packItem);
        return packItem;
      });
    },

    addPackageFeatures(packItem) {
      packItem.quantity = {
        actualQuantity: packItem.actual_quantity,
        requiredQuantity: packItem.required_quantity,
      };
      packItem.startTime = {
        date: this.formatDate(packItem.timestamp),
        time: this.formatTime(packItem.timestamp),
      };
      packItem.endTime = {
        date: this.formatDate(packItem.end_time),
        time: this.formatTime(packItem.end_time),
      };
      packItem.status = {
        erpStatus: packItem.erp_status,
        packageStatus: packItem.package_status,
        packIcon: require("../../assets/check-icon.svg"),
        errorIcon: require("../../assets/error.svg"),
      };

      return packItem;
    },

    formatDate(data) {
      return format(parseISO(data), "dd.MM.yy");
    },
    formatTime(data) {
      return format(parseISO(data), "HH:mm:ss");
    },

    showUnLoadCurrentModal() {
      this.isClosePackage = false;
      this.isUnloadCurrent = true;
      this.isUnloadNext = false;
      this.$refs.PinDialogRef.showPinModal();
    },

    unLoadCurrentOrder(pinCode) {
      this.$refs.PinDialogRef?.startUnloadOrderLoader();
      let newData = {
        pin: pinCode,
        order_id: this.currentOrder.id,
      };
      Api()
        .post(
          `/station/unload-order/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          newData
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.$refs.PinDialogRef.stopUnLoadOrderLoader();
            this.$refs.PinDialogRef.closePinModal();
            this.showSuccessAlert(res.data.detail);
            this.getLabelStationStats(false);
          }
        })
        .catch((err) => {
          this.showError(err);
          this.$refs.PinDialogRef.stopUnLoadOrderLoader();
        });
    },

    showUnLoadNextModal() {
      this.isClosePackage = false;
      this.isUnloadCurrent = false;
      this.isUnloadNext = true;
      this.$refs.PinDialogRef.showPinModal();
    },

    unLoadNextOrder(pinCode) {
      this.$refs.PinDialogRef?.startUnloadOrderLoader();
      let newData = {
        pin: pinCode,
        order_id: this.nextOrder.id,
      };
      Api()
        .post(
          `/station/unload-order/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          newData
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.$refs.PinDialogRef.stopUnLoadOrderLoader();
            this.$refs.PinDialogRef.closePinModal();
            this.showSuccessAlert(res.data.detail);
            this.getLabelStationStats(false);
          }
        })
        .catch((err) => {
          this.showError(err);
          this.$refs.PinDialogRef.stopUnLoadOrderLoader();
        });
    },

    showClosePackageModal(data) {
      this.isClosePackage = true;
      this.isUnloadCurrent = false;
      this.isUnloadNext = false;
      this.$refs.PinDialogRef.showPinModal();
      this.currentPackage = data;
    },

    closePackage(pinCode) {
      this.$refs.PinDialogRef?.startUnloadOrderLoader();
      let newData = {
        pin: pinCode,
        package_id: this.currentPackage.id,
      };
      Api()
        .post(
          `/station/close-package/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          newData
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.$refs.PinDialogRef.stopUnLoadOrderLoader();
            this.$refs.PinDialogRef.closePinModal();
            this.showSuccessAlert(res.data.detail);
            this.getLabelStationStats(false);
          }
        })
        .catch((err) => {
          this.showError(err);
          this.$refs.PinDialogRef.stopUnLoadOrderLoader();
        });
    },

    getMachineId() {
      this.aftMacId = localStorage.getItem("aftMacId");
    },

    getMachineIdFromRoute() {
      this.routeMachineId = this.$route.params.routeMachineId
        ? this.$route.params.routeMachineId
        : this.dynamicMachineId;
    },

    showSuccessAlert(data) {
      this.successMsg = data;
      this.showSuccessMsg = true;
      setTimeout(() => {
        this.showSuccessMsg = false;
        this.successMsg = "";
      }, 3000);
    },

    showErrorAlert(errorMessage) {
      let msg = "Authentication credentials were not provided.";
      if (msg == errorMessage && this.atlViewLang === "en") {
        this.longAlertMsg = "Please log in to perform this action";
        this.isLongAlert = true;
        this.$refs.PinDialogRef.closePinModal();
      } else if (msg == errorMessage && this.atlViewLang === "de") {
        this.longAlertMsg =
          "Bitte melden Sie sich an, um diese Aktion durchzuführen";
        this.isLongAlert = true;
        this.$refs.PinDialogRef.closePinModal();
      } else {
        this.showAlert = true;
        this.alertColor = "#e76a6a";
        this.textColor = "#ffffff";
        this.alertMessage = errorMessage;
        this.isSuccess = false;
      }
      this.scrollToTop();
    },

    scrollToTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100);
    },

    hideAlert() {
      this.showAlert = false;
    },

    showSuccess(message) {
      this.showAlert = true;
      this.alertColor = "#A6F4C5";
      this.textColor = "#054F31";
      this.alertMessage = message;
      this.isSuccess = true;
    },

    showError(err) {
      let errorData = err?.response?.data;
      let errorMessage = errorData?.messages
        ? errorData?.messages[0].message
        : errorData?.detail
        ? errorData?.detail
        : "An unexpected error occurred";

      this.showErrorAlert(errorMessage);
    },

    ...mapActions("users", [
      "setCurrentShiftStartDate",
      "setCurrentShiftEndDate",
    ]),
    ...mapActions("machines", ["setMachineStatus", "setMachineName"]),
  },

  mounted() {
    this.$watch(
      "$route.path",
      function () {
        this.currentTab = this.$router.history.current.fullPath;
      },
      { deep: true }
    );

    this.getLabelStationStats(true);
  },

  created() {
    this.getMachineId();
    this.getMachineIdFromRoute();
  },

  unmounted() {
    clearInterval(this.timer);
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.v-label {
  font-weight: 600;
  font-size: 18px;
  color: #344054;
}

.v-text-field {
  border-radius: 8px !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.create-pack-btn {
  color: #0408e7;
  font-size: 14px;
}

.create-order-btn {
  font-weight: 400;
  font-size: 14px;
  border: 1px solid #d0d5dd !important;
}

.package-cancel-btn {
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px !important;
  color: #344054;
  border: 1px solid #d0d5dd !important;
}

.package-save-btn {
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px !important;
  color: #ffffff;
}

.cancel-btn {
  border: 1px solid #d0d5dd;
}

.button-div button {
  border: 1px solid #d0d5dd;
  border-radius: 8px;
}

.buttonClicked {
  background-color: #9b9dfd;
  color: #ffffff;
}

* /deep/.v-data-table tr:nth-child(2n + 1) {
  background-color: #fcfcfd;
}

* /deep/thead tr:first-child {
  background-color: #ffffff !important;
}

* /deep/ thead tr th {
  color: #667085 !important;
  font-weight: 400;
  font-size: 12px;
}

.atlWrapper {
  height: calc(100vh - 72px);
}

.position-alert {
  position: absolute;
  top: 0%;
  right: 5%;
  z-index: 999;
}
</style>
