<template>
  <div class="bg-white">
    <div class="w-[95%] pl-14">
      <v-card elevation="0">
        <v-card-text>
          <div
            class="flex flex-col lg:flex-row items-center justify-between my-4"
          >
            <div class="machine-text">{{ $t("machineview.machine") }}</div>
            <div
              class="flex flex-col lg:flex-row items-center justify-between gap-x-10"
            >
              <!-- Search input field  -->
              <div>
                <div
                  class="w-full bg-[#ffffff] border border-[#D0D5DD] rounded-lg flex flex-row justify-between items-center align-middle h-[50px]"
                >
                  <div class="py-5 pl-5">
                    <v-img src="@/assets/searchIcon.svg"></v-img>
                  </div>
                  <input
                    class="flex flex-row justify-between flex-1 p-4 mx-auto w-[375px] align-middle outline-none"
                    :placeholder="$t('theinput.search')"
                    v-model="search"
                    type="search"
                    @keyup.enter="fetchSearchItems"
                  />
                </div>
              </div>
              <!-- Add Machine dialog box  -->
              <v-dialog v-model="dialogAdd" width="577">
                <template v-slot:activator="{ on, attrs }"
                  ><v-btn
                    height="40"
                    depressed
                    color="#F5F5FF"
                    elevation="0"
                    class="add-btn"
                    v-bind="attrs"
                    v-on="on"
                    >{{ $t("machineview.add") }}</v-btn
                  ></template
                >
                <div class="w-[577px]">
                  <v-card class="p-1">
                    <v-form ref="machineForm">
                      <v-card-title>
                        <div
                          class="mx-auto font-semibold text-lg text-[#101828]"
                        >
                          {{ $t("machineview.addMachine") }}
                        </div>
                        <div>
                          <v-img
                            @click="dialogAdd = false"
                            :src="require(`../../assets/close-icon.svg`)"
                            class="cursor-pointer"
                          >
                          </v-img>
                        </div>
                      </v-card-title>
                      <v-card-text>
                        <v-label>{{ $t("machineview.name") }}</v-label>
                        <v-text-field
                          dense
                          ref="name"
                          v-model="machineName"
                          outlined
                          color="#667085"
                        ></v-text-field>
                        <v-label>{{ $t("machineview.number") }}</v-label>
                        <v-text-field
                          dense
                          ref="name"
                          v-model="machineNumber"
                          outlined
                          color="#667085"
                          :rules="[noSpaceAllowed(machineViewLang)]"
                        ></v-text-field>
                        <v-label>{{ $t("machineview.assignGroup") }}</v-label>
                        <v-select
                          :label="$t('machineview.select')"
                          :items="machineGroups"
                          item-text="name"
                          item-value="id"
                          v-model="selectedMachineGroupId"
                          dense
                          outlined
                          single-line
                        ></v-select>
                        <div class="flex justify-start gap-4">
                          <div>
                            <v-label>{{ $t("machineview.pin") }}</v-label>
                            <v-text-field
                              dense
                              ref="name"
                              v-model="machinePin"
                              outlined
                              color="#667085"
                              :rules="[onlyNumbers(machineViewLang)]"
                            ></v-text-field>
                          </div>

                          <div>
                            <v-label>{{
                              $t("machineview.totalFault")
                            }}</v-label>
                            <v-text-field
                              dense
                              ref="name"
                              v-model="machineFault"
                              outlined
                              color="#667085"
                            ></v-text-field>
                          </div>

                          <div>
                            <v-label>{{ $t("machineview.totalNIO") }}</v-label>
                            <v-text-field
                              dense
                              ref="name"
                              v-model="machineNio"
                              outlined
                              color="#667085"
                            ></v-text-field>
                          </div>
                        </div>

                        <div class="flex justify-start gap-4">
                          <div>
                            <v-label>{{ $t("machineview.imei") }}</v-label>
                            <v-text-field
                              dense
                              ref="name"
                              v-model="machineImei"
                              outlined
                              color="#667085"
                            ></v-text-field>
                          </div>
                          <div>
                            <v-label>{{ $t("machineview.setPoint") }}</v-label>
                            <v-text-field
                              dense
                              ref="name"
                              v-model="machinePoint"
                              outlined
                              color="#667085"
                            ></v-text-field>
                          </div>
                          <div>
                            <v-label>{{ $t("machineview.aftSite") }}</v-label>
                            <v-text-field
                              dense
                              ref="name"
                              v-model="aftSite"
                              outlined
                              color="#667085"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="flex justify-between">
                          <div>
                            <v-btn
                              elevation="0"
                              class="cancel-btn !bg-[#ffffff]"
                              @click="dialogAdd = false"
                              >{{ $t("machineview.cancel") }}</v-btn
                            >
                          </div>
                          <div>
                            <v-btn
                              elevation="0"
                              depressed
                              color="primary"
                              class="save-btn"
                              :loading="addMachineLoader"
                              @click="addMachine"
                              >{{ $t("machineview.save") }}</v-btn
                            >
                          </div>
                        </div>
                      </v-card-text>
                    </v-form>
                  </v-card>
                </div>
              </v-dialog>
            </div>
          </div>
          <v-divider class="my-6"></v-divider>
          <!-- Machines table  -->
          <v-data-table
            dense
            :headers="
              machineViewLang === 'en'
                ? headers
                : machineViewLang === 'de'
                ? deHeaders
                : machineViewLang === 'cn'
                ? cnHeaders
                : machineViewLang === 'ro'
                ? roHeaders
                : machineViewLang === 'ba'
                ? baHeaders
                : machineViewLang === 'es'
                ? esHeaders
                : rsHeaders
            "
            :items="formattedMachines"
            item-key="id"
            class="border-2"
            :hide-default-footer="true"
            :no-data-text="$t('machineview.nodata')"
          >
            <template v-slot:[`header.icons`]="{}">
              <div class="flex justify-end mt-5 mr-12">
                <div>{{ $t("machineview.actions") }}</div>
              </div>
            </template>
            <template v-slot:[`item.icons`]="{ item }">
              <div class="flex justify-end gap-5 pr-10">
                <div class="w-[15px] cursor-pointer">
                  <v-dialog
                    :retain-focus="false"
                    v-model="dialogDelete"
                    width="400"
                    overlay-opacity="0.1"
                    content-class="elevation-0"
                  >
                    <template v-slot:activator="{}">
                      <v-img
                        :src="item.deleteEdit.delete"
                        :lazy-src="item.deleteEdit.delete"
                        @click="showDeleteDialog(item)"
                      ></v-img>
                    </template>
                    <div class="w-[400px]">
                      <v-card class="p-1">
                        <v-card-title class="flex justify-between">
                          <div class="text-lg text-[#101828] font-semibold">
                            {{ $t("machineview.deleteMachine") }}
                          </div>
                          <div>
                            <v-img
                              @click="dialogDelete = false"
                              :src="require(`../../assets/close-icon.svg`)"
                              class="cursor-pointer"
                            >
                            </v-img>
                          </div>
                        </v-card-title>
                        <v-card-text>
                          <div>
                            {{ $t("machineview.areYouSure") }}
                            <span class="font-bold">{{
                              selectedMachine?.machine_name
                            }}</span>
                            {{ $t("machineview.thisAction") }}
                          </div>
                          <div class="flex justify-between pt-5">
                            <div>
                              <v-btn
                                @click="dialogDelete = false"
                                class="machine-cancel-btn !bg-[#ffffff]"
                                elevation="0"
                                depressed
                                >{{ $t("machineview.cancel") }}</v-btn
                              >
                            </div>
                            <div>
                              <v-btn
                                class="machine-del-btn"
                                elevation="0"
                                depressed
                                color="#D92D20"
                                :loading="deleteMachineLoader"
                                @click="removeMachine"
                                >{{ $t("machineview.yesDelete") }}</v-btn
                              >
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-dialog>
                </div>
                <div class="w-[15px] cursor-pointer">
                  <v-img
                    :src="item.deleteEdit.edit"
                    :lazy-src="item.deleteEdit.edit"
                    @click="startEditMachine(item)"
                  ></v-img>
                </div>
              </div>
            </template>
          </v-data-table>
          <div class="flex justify-end mt-4">
            <v-pagination
              class="paginate_class"
              v-model="page"
              :length="totalPages"
              :total-visible="8"
              next-icon="mdi-arrow-right"
              prev-icon="mdi-arrow-left"
              @input="handlePageChange"
              color="#F5F5F5"
            ></v-pagination>
          </div>
          <EditMachineDialog
            :selectedMachine="selectedMachine"
            :machineGroups="machineGroups"
            @update-machineName="updateMachineName"
            @update-machineNumber="updateMachineNumber"
            @update-machinePin="updateMachinePin"
            @update-machineImei="updateMachineImei"
            @update-machinePoint="updateMachinePoint"
            @update-machineFault="updateMachineFault"
            @update-machineNio="updateMachineNio"
            @update-aftSite="updateAftSite"
            @update-machine="updateMachine"
            ref="EditMachineDialogRef"
          />
        </v-card-text>
      </v-card>
    </div>
    <v-overlay
      :value="allMachinesLoader || allMachineGroupsLoader"
      color="grey"
    >
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <div class="position-alert" v-if="showAlert">
      <AlertComponent
        :alertColor="alertColor"
        :textColor="textColor"
        :alertMessage="alertMessage"
        :isSuccess="isSuccess"
        @hide-alert="hideAlert"
      />
    </div>
  </div>
</template>

<script>
import Api from "../../services/api";
import debounce from "lodash.debounce";
import EditMachineDialog from "../../components/admin/EditMachineDialog.vue";
import AlertComponent from "../../components/AlertComponent.vue";
import validation from "../../services/validation";

export default {
  components: { EditMachineDialog, AlertComponent },
  data() {
    return {
      search: "",
      machineName: "",
      machineNumber: "",
      assignGroup: "",
      machinePin: "",
      machineImei: "",
      machinePoint: "",
      machineFault: "",
      machineNio: "",
      aftSite: "",
      dialogAdd: false,
      dialogDelete: false,
      ...validation,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        {
          text: "Name",
          value: "machine_name",
        },
        { text: "Number", value: "number" },
        { text: "PIN", value: "pin" },
        { text: "", value: "icons" },
      ],
      deHeaders: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        {
          text: "Name",
          value: "machine_name",
        },
        { text: "Nummer", value: "number" },
        { text: "PIN", value: "pin" },
        { text: "", value: "icons" },
      ],
      cnHeaders: [
        {
          text: "身份证",
          align: "start",
          value: "id",
        },
        {
          text: "命名",
          value: "machine_name",
        },
        { text: "数量", value: "number" },
        { text: "密码", value: "pin" },
        { text: "", value: "icons" },
      ],
      roHeaders: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        {
          text: "Nume",
          value: "machine_name",
        },
        { text: "Număr", value: "number" },
        { text: "PIN", value: "pin" },
        { text: "", value: "icons" },
      ],
      esHeaders: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        {
          text: "Nombre",
          value: "machine_name",
        },
        { text: "Número", value: "number" },
        { text: "PIN", value: "pin" },
        { text: "", value: "icons" },
      ],
      baHeaders: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        {
          text: "Ime",
          value: "machine_name",
        },
        { text: "Broj", value: "number" },
        { text: "PIN", value: "pin" },
        { text: "", value: "icons" },
      ],
      rsHeaders: [
        {
          text: "ИД",
          align: "start",
          value: "id",
        },
        {
          text: "Име",
          value: "machine_name",
        },
        { text: "Број", value: "number" },
        { text: "ПИН", value: "pin" },
        { text: "", value: "icons" },
      ],
      machines: [
        {
          name: "SAE3",
          number: 89,
          pin: 89,
          deleteIcon: require("../../assets/delete-icon.svg"),
          editIcon: require("../../assets/edit-icon.svg"),
        },
      ],
      allMachinesLoader: false,
      formattedMachines: [],
      addMachineLoader: false,
      allMachineGroupsLoader: false,
      machineGroups: [],
      selectedMachineGroupId: "",
      selectedMachine: null,
      deleteMachineLoader: false,
      page: 1,
      totalPages: 1,
      perPage: 10,
      totalMachinesCount: 1,

      showAlert: false,
      alertColor: "",
      textColor: "",
      alertMessage: "",
      isSuccess: true,
    };
  },

  methods: {
    // Fetch search results
    fetchSearchItems() {
      Api()
        .get(`/machines/all-machines/${this.page}`, {
          params: {
            search_query: this.search,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            this.formattedMachines = [
              ...this.formatMachinesData(res.data.data),
            ];
            this.totalPages = res.data.total_pages;
            this.totalMachinesCount = res.data.total_machines;
          }
        })
        .catch((err) => {
          this.showError(err);
        });
    },

    // Fetch all machines
    getAllMachines(showLoader) {
      this.allMachinesLoader = showLoader;
      Api()
        .get(`/machines/all-machines/${this.page}/`)
        .then((res) => {
          if (res.data.status === "success") {
            this.formattedMachines = [
              ...this.formatMachinesData(res.data.data),
            ];
            this.totalPages = res.data.total_pages;
            this.totalMachinesCount = res.data.total_machines;
          }
          this.allMachinesLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.allMachinesLoader = false;
        });
    },

    handlePageChange(value) {
      this.page = value;
      this.getAllMachines(true);
      this.scrollToTop();
    },

    getMachineGroups() {
      this.allMachineGroupsLoader = true;
      Api()
        .post("/machines/get-dropdowns/4/")
        .then((res) => {
          if (res.data.status == "success") {
            this.machineGroups = [...res.data.data];
          }
          this.allMachineGroupsLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.allMachineGroupsLoader = false;
        });
    },

    // Adding a machine
    addMachineFeatures(machine) {
      machine.deleteEdit = {
        delete: require("../../assets/delete-icon.svg"),
        edit: require("../../assets/edit-icon.svg"),
      };
      machine.name = machine.machine_name;
      machine.number = machine.machine_number;
      return machine;
    },

    formatMachinesData(machines) {
      return machines.map((machine) => {
        machine = this.addMachineFeatures(machine);
        return machine;
      });
    },

    addToMachinesList(machine) {
      machine = this.addMachineFeatures(machine);
      this.formattedMachines.unshift(machine);
      this.totalMachinesCount++;
      this.totalPages = Math.ceil(this.totalMachinesCount / this.perPage);
    },

    addMachine() {
      this.addMachineLoader = true;
      let newData = {
        machine_name: this.machineName,
        machine_number: this.machineNumber,
        pin: this.machinePin,
        machine_group_id: this.selectedMachineGroupId,
        imei_number: this.machineImei,
        set_point: this.machinePoint,
        max_fault: this.machineFault,
        max_nio: this.machineNio,
        aft_site: this.aftSite,
      };
      Api()
        .post("/machines/add-machine/", newData)
        .then((res) => {
          if (res.data.status == "success") {
            this.addToMachinesList(res.data.data[0]);
            this.removeLastMachine();
            this.dialogAdd = false;
            this.showSuccess(res.data.detail);
          }
          this.addMachineLoader = false;
          this.resetMachineData();
        })
        .catch((err) => {
          this.showError(err);
          this.addMachineLoader = false;
        });
    },

    removeLastMachine() {
      if (this.perPage < this.totalMachinesCount) {
        this.formattedMachines.splice(this.perPage, 1);
      }
    },

    // Deleting a machine
    showDeleteDialog(data) {
      this.dialogDelete = true;
      this.selectedMachine = { ...data };
    },

    removeFromMachinesList(machineId) {
      let machines = [...this.formattedMachines];
      this.formattedMachines = machines.filter(
        (machine) => machine.id !== machineId
      );
    },

    removeMachine() {
      this.deleteMachineLoader = true;
      Api()
        .delete(`/machines/delete-machine/${this.selectedMachine.id}/`)
        .then((res) => {
          if (res.data.status == "success") {
            this.removeFromMachinesList(this.selectedMachine.id);
            this.getAllMachines(false);
            this.dialogDelete = false;
            this.showSuccess(res.data.detail);
          }
          this.deleteMachineLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.deleteMachineLoader = false;
        });
    },

    resetMachineData() {
      this.machineName = "";
      this.machineNumber = "";
      this.machinePin = "";
      this.machineImei = "";
      this.machinePoint = "";
      this.machineFault = "";
      this.machineNio = "";
      this.aftSite = "";
      this.selectedMachineGroupId = "";
    },

    // Updating Machine Details
    startEditMachine(data) {
      this.selectedMachine = { ...data };
      this.showEditModal();
    },

    updateMachine(data) {
      Api()
        .put(`/machines/update-machine/${data.id}/`, data)
        .then((res) => {
          if (res.data.status == "success") {
            this.updateMachinesList(res.data.data);
            this.showSuccess(res.data.detail);
            this.closeEditModal();
          }
          this.stopEditLoader();
        })
        .catch((err) => {
          this.showError(err);
          this.stopEditLoader();
        });
    },

    updateMachinesList(machine) {
      machine = this.addMachineFeatures(machine);
      this.findAndReplaceMachine(machine);
    },

    findAndReplaceMachine(updatedMachine) {
      let foundIndex = this.formattedMachines.findIndex(
        (machine) => machine.id == updatedMachine.id
      );
      if (foundIndex == 0 || foundIndex) {
        this.formattedMachines.splice(foundIndex, 1, updatedMachine);
      }
    },

    updateMachineName(data) {
      this.selectedMachine.machine_name = data;
    },

    updateMachineNumber(data) {
      this.selectedMachine.machine_number = data;
    },

    updateMachinePin(data) {
      this.selectedMachine.pin = data;
    },

    updateMachineImei(data) {
      this.selectedMachine.imei_number = data;
    },

    updateMachinePoint(data) {
      this.selectedMachine.set_point = data;
    },

    updateMachineFault(data) {
      this.selectedMachine.max_fault = data;
    },

    updateMachineNio(data) {
      this.selectedMachine.max_nio = data;
    },

    updateAftSite(data) {
      this.selectedMachine.aft_site = data;
    },

    showEditModal() {
      this.$refs.EditMachineDialogRef?.showEditDialog();
    },

    closeEditModal() {
      this.$refs.EditMachineDialogRef?.closeEditDialog();
    },

    stopEditLoader() {
      this.$refs.EditMachineDialogRef?.stopEditLoader();
    },

    closeAddMachineDialog() {
      this.$refs.machineForm.reset();
    },

    hideAlert() {
      this.showAlert = false;
    },

    showSuccess(message) {
      this.showAlert = true;
      this.alertColor = "#A6F4C5";
      this.textColor = "#054F31";
      this.alertMessage = message;
      this.isSuccess = true;
      this.scrollToTop();
    },

    scrollToTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100);
    },

    showError(err) {
      let errorData = err?.response?.data;
      let errorMessage = errorData?.messages
        ? errorData?.messages[0].message
        : errorData?.detail
        ? errorData?.detail
        : "An unexpected error occurred";

      this.showAlert = true;
      this.alertColor = "#e76a6a";
      this.textColor = "#ffffff";
      this.alertMessage = errorMessage;
      this.isSuccess = false;
      this.scrollToTop();
    },
  },

  mounted() {
    this.getAllMachines(true);
    this.getMachineGroups();
    this.scrollToTop();
    this.debounceSearchResults = debounce(this.fetchSearchItems, 100);
  },

  computed: {
    machineViewLang() {
      return this.$store.state.translation.i18n.locale;
    },
  },

  watch: {
    dialogAdd(val) {
      !val && this.closeAddMachineDialog();
    },
    search(...args) {
      this.debounceSearchResults(args);
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.machine-text {
  font-size: 18px;
  font-weight: 600;
  color: #101828;
}

.add-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #f5f5ff;
  color: #0408e7;
}

.theme--light.v-divider {
  color: #eaecf0;
}

.delete-text {
  color: #667085;
}

.edit-text {
  color: #0408e7;
}

* /deep/ .v-pagination__item {
  border-radius: 0px;
  box-shadow: none;
  margin: 0rem;
  height: 40px;
  width: 40px;
  border: 0.5px solid #d0d5dd;
}

* /deep/ .v-pagination__more {
  margin: 0rem;
  height: 40px;
  width: 40px;
  border: 0.5px solid #d0d5dd;
}

* /deep/ .v-pagination__navigation {
  height: 38px;
  width: 38px;
  margin: 0rem;
  box-shadow: none;
}

* /deep/ .theme--light.v-pagination .v-pagination__item--active {
  color: #667085;
  border-color: #d0d5dd !important;
}

* /deep/ .mdi-arrow-left::before {
  font-size: 18px;
}

* /deep/ .mdi-arrow-right::before {
  font-size: 18px;
}

* /deep/ .paginate_class li:first-child {
  border: 0.1px solid #d0d5dd;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

* /deep/ .paginate_class li:last-child {
  border: 0.1px solid #d0d5dd;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

* /deep/.v-data-table tr:nth-child(2n + 1) {
  background-color: #fcfcfd;
}

* /deep/thead tr:first-child {
  background-color: #ffffff !important;
}

* /deep/ thead tr th {
  color: #667085 !important;
  font-weight: 400;
  font-size: 12px;
}

.v-label {
  font-weight: 400;
  font-size: 14px;
  color: #344054;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.v-text-field {
  border-radius: 8px !important;
}

.cancel-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
  width: 258px;
}

.save-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
  width: 258px;
}

.machine-del-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  width: 170px;
  color: #ffffff;
}

.machine-cancel-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  width: 170px;
  color: #344054;
  border: 1px solid #d0d5dd;
}

.position-alert {
  position: absolute;
  top: 0%;
  right: 5%;
  z-index: 999;
}
</style>
